/* ======================  SCRIPTS ====================== */

    var nbChampVide;

    $('document').ready(setup);
    window.onload = goestoAnchor;

    function setup() {
        initTarteAuCitron();
        initPopup();
        $('.slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1035,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 805,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
        $('.header-slider').slick({
            dots: false,
            infinite: true,
            speed: 1600,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)'
        });
        $('.sliderWrapper').slick({
            dots: false,
            infinite: true,
            speed: 600,
            autoplaySpeed: 1800,
            autoplay: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: $('.sliderprev'),
            nextArrow: $('.slidernext'),
            responsive: [{
                breakpoint: 5000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
        adjustMarginHeader();
        adjustPositionIncard();
        adjustBurgerMenuPos();
        addSubmenuButtons();
        $('.burger').click(showHideMenuResponsive);
        hoverSubmenu();
        $(window).on('resize', adjustMarginHeader);
        $(window).on('resize', adjustPositionIncard);
        $(window).on('resize', adjustBurgerMenuPos);
        $(window).scroll(stickyMenu);
        $(window).scroll(showHideTopPage);
        $('.videoblock').click(showVideo);
        $('.btn-topage').click(goTopPage);
        $('.offerbu .btn').click(goToAnchorOffer);
        $('.landing a:not(.cd-popup-trigger)').click(checkIfAnchor);
        $('.landing .wpcf7-form .wpcf7-submit').click(testerFormulaire);

    }

    function initTarteAuCitron() {
        tarteaucitron.init({
            "hashtag": "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
            "cookieName": "rma", /* Cookie name */
            "DenyAllCta" : true, /* Show the deny all button */
            "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
            "highPrivacy": true, /* désactiver le consentement implicite (en naviguant) ? */
            "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
            "orientation": "middle", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
            "showAlertSmall": true, /* afficher le petit bandeau en bas à droite ? */
            "cookieslist": true, /* Show the cookie list */
            "closePopup": false, /* Show a close X on the banner */
            "showIcon": false, /* Show cookie icon to manage cookies */
            "adblocker": false, /* Show a Warning if an adblocker is detected */
            "removeCredit": true, /* supprimer le lien vers la source ? */
            "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
            "moreInfoLink": true, /* Show more info link */
            "readmoreLink": "", /* Change the default readmore link */
            "gaTimeout":800
        });

        (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
        tarteaucitron.user.googlemapsKey = 'AIzaSyC469x6Pibv_r3IEwltLnqIQ95wRf-m5o4';
        (tarteaucitron.job = tarteaucitron.job || []).push('googlemaps');
    }

    function hoverSubmenu() {
        $(".menu > .menu-item-has-children:not(.offersmegamenu)").mouseenter(function(){
            $(this).children('ul').slideDown(400);
        }).mouseleave(function(){
            $(this).children('ul').slideUp(200);
        });
    }

    // Fonction qui permet de gérer le scroll sur les ancres
    function goestoAnchor() {
        window.scroll(0, 0);
        var url = window.location.href;
        if(url.indexOf('#') != -1) {
            var id = url.split('#')[1];
            var offset = $('#'+id).offset().top - 160;
            $('html, body').animate({scrollTop: offset}, 1200);
        }
    }

    // Fonction qui permet d'ajouter les boutons pour ouvrir les sous-menu du menu responsive
    function addSubmenuButtons() {
        $('.navresponsive .menu-item-has-children').append('<button class="btn openSubmenu">Ouvrir le sous-menu</button>');
        $('.openSubmenu').click(openSubmenuResp);
    }

    // Fonction qui permet de gérer l'affichage du menu responsif
    function showHideMenuResponsive() {
        var menuWidth = parseInt($('.navresponsive').width());
        var bodyHeight = $('body').height();

        $('.fadebg').css('height', bodyHeight+50+55);
        $('.fadebg').css('visibility', 'visible');

        if($(this).hasClass('active')) {
            $('#socle-content').removeAttr('style');
            $(this).removeClass('active');
            $('html').removeAttr('style');
            $('body').removeAttr('style');
            $('.fadebg').removeClass('show');
            $('.fadebg').removeAttr('style');
            $('.fadebg').css('height', bodyHeight);

            $('.bodycontent').removeAttr('style');
            $('#socle-content').removeAttr('style');
            $('.navigation').removeAttr('style');
            $('.btn-topage').fadeIn();
            setTimeout(function() { $('.navresponsive').removeAttr('style'); }, 500);
            adjustBurgerMenuPos();
        }else {
            $('.navresponsive').show();
            $('.fadebg').addClass('show');
            $(this).removeAttr('style');
            $(this).addClass('active');
            $('.navresponsive').css('right', '0');
            $('#socle-content').css('z-index', '2');
            $('html').css('overflow', 'hidden');
            $('body').css('overflow', 'hidden');
            $('.bodycontent').css('right', menuWidth + 'px');
            $('#socle-content').css('right', menuWidth + 'px');
            $('.navigation').css('left', -menuWidth + 'px');
            $('.btn-topage').hide();
        }
    }

    // Fonction qui permet d'afficher/cacher les sous menu du menu responsive
    function openSubmenuResp() {
        if($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().children('ul').slideUp(400, function() {
                var menuWidth = parseInt($('.navresponsive').width());
                $('.bodycontent').css('right', menuWidth + 'px');
                $('#socle-content').css('right', menuWidth + 'px');
                $('.navigation').css('left', -menuWidth + 'px');
            });
        }else {
            $(this).addClass('active');
            $(this).parent().children('ul').slideDown(400, function() {
                var menuWidth = parseInt($('.navresponsive').width());
                $('.bodycontent').css('right', menuWidth + 'px');
                $('#socle-content').css('right', menuWidth + 'px');
                $('.navigation').css('left', -menuWidth + 'px');
            });
        }
    }

    // Fonction qui permet de rendre le menu sticky
    function stickyMenu() {
        if($(this).scrollTop() > $('.navigation').innerHeight()) {
            $('.navigation').addClass('sticky');
        }else {
            $('.navigation').removeClass('sticky');
        }
    }

    // Fonction qui permet d'adapter l'affichage responsive du bloc news dans un header
    function adjustMarginHeader() {
        if($('.header-news').length !=0 && $('.homepage').length !=0 && $(window).width() < 760) {
            var headerNewsHeight = $('.header-news').innerHeight() + 30;
            $('.header').css('margin-bottom', headerNewsHeight + 'px');
        }else if($('.homepage').length !=0) {
            if($(window).width() < 1200) {
                $('.header').css('margin-bottom', '80px');
            }else {
                $('.header').css('margin-bottom', '65px');
            }

        }
    }

    // Fonction qui permet d'ajuster le placement du bloc menu responsive
    function adjustBurgerMenuPos() {
        if($('.burger').hasClass('active') == false) {
            var espaceClientWidth = $('.client').innerWidth() + 20;
            $('.burger').css('right', espaceClientWidth + 'px');

            setTimeout(function() {
                var espaceClientWidth = $('.client').innerWidth() + 20;
                $('.burger').css('right', espaceClientWidth + 'px');
            }, 500);
        }
    }

    // Fonction qui permet de montrer la vidéo dans les blocs vidéos avec couverture image
    function showVideo() {
        if(!($(this).hasClass('showVideo'))) {
            $(this).addClass('showVideo');
            $('.videoblock-iframe').animate({ top: '0'}, 1500);
        }
    }

    // Fonction qui permet d'aligner le texte des cartes au milieu pour Firefox et IE
    function adjustPositionIncard() {
        var navigator = window.navigator.vendor;
        $('.card').each(function() {
            var cardHeight = $(this).height();
            var cardHeightContent = $(this).children('.card-content').height();
            var top = cardHeight - (((cardHeight - cardHeightContent) / 2) + cardHeightContent);
            if(navigator == '' || navigator == ' ') {
                $('.card-content').css('top', top + 'px');
            }
        });
    }

    // Fonctions pour permettre à l'utilisateur de retourner facilement en haut de page
    function goTopPage() {
        $('html, body').animate({scrollTop: 0}, 1500);
    }

    // Fonctions qui permettent d'afficher le bouton pour retourner en haut de page
    function showHideTopPage() {
        if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
            if($(window).width() > 650) {
                $('.btn-topage').css('bottom', '14%');
            }
            $('.btn-topage').css('background-color', '#3a3a3a');
        }else {
            $('.btn-topage').removeAttr('style');
        }
        if($(this).scrollTop() >= 1000){
            $('.btn-topage').addClass('show');
        }else {
            $('.btn-topage').removeClass('show');
        }
    }

    // Fonction qui permet de scroller vers les catégories d'offres de RMA
    function goToAnchorOffer(e) {
        var url = $(this).attr('href');
        if(url.indexOf('#') !== -1) {
            e.preventDefault();
            var id = url.split('#')[1];
            var offset = $('#'+id).offset().top - 160;
            $('html, body').animate({scrollTop: offset}, 1200);
        }
    }

    // Fonction qui permet de charger la carte de la page contact
    function initMap() {
        // On récupère la moyenne des longitudes et latitudes pour centrer la carte sur les agences affichées
        var latitudeMoy = 0;
        var longitueMoy = 0;

        if($('.clusters li').length != 0) {
            $('.clusters li').each(function( index ) {
                var latitude = $(this).attr('data-latitude');
                var longitude = $(this).attr('data-longitude');

                latitudeMoy = latitudeMoy + parseFloat(latitude);
                longitueMoy = longitueMoy + parseFloat(longitude);
            });

            var latCenterMap = latitudeMoy / $('.clusters li').length;
            var longCenterMap = longitueMoy / $('.clusters li').length;

            var centerMap = {lat: parseFloat(latCenterMap), lng: parseFloat(longCenterMap)};

            if($(window).width() < 640) {
                var zoomMap = 8;
            }else {
                var zoomMap = 10;
            }
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: zoomMap,
                center: centerMap,
                scrollwheel: false,
                styles: [
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "stylers": [
                            {
                                "hue": "#00aaff"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "gamma": 2.15
                            },
                            {
                                "lightness": 12
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": 24
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": 57
                            }
                        ]
                    }
                ]
            });

            var localizationPointer = {
                path: 'M19,0.5c-9.9,0-18,8.1-18,18c0,7.7,4.9,14.2,11.7,16.8l1.8,5.5L19,54.5l4.5-13.7l1.8-5.5' +
                'C32.1,32.7,37,26.2,37,18.5C37,8.6,28.9,0.5,19,0.5z M18.7,23.9c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4' +
                'S21.7,23.9,18.7,23.9z',
                fillColor: '#ffdd02',
                fillOpacity: 1.0,
                scale: 1,
                strokeWeight: 0,
                size: new google.maps.Size(38, 55),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 55)
            };

            $('.clusters li').each(function( index ) {
                var latitude = $(this).attr('data-latitude');
                var longitude = $(this).attr('data-longitude');
                var positionMarker = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
                var text = $(this).attr('data-text');
                var contentString = '<div id="content">'+ text + '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content: contentString
                });

                var marker = new google.maps.Marker({
                    position: positionMarker,
                    map: map,
                    animation: google.maps.Animation.DROP,
                    icon: localizationPointer
                });

                marker.addListener('click', function() {
                    if (typeof( window.infoopened ) != 'undefined') infoopened.close();
                    infowindow.open(map, marker);
                    infoopened = infowindow;
                });
            });

            var myoverlay = new google.maps.OverlayView();
            myoverlay.draw = function () {
                this.getPanes().markerLayer.id='markerLayer';
            };
            myoverlay.setMap(map);
        }
    }

    //Popup landing page
    function initPopup() {
        $('.cd-popup-trigger').on('click', function(event){
            let id = $(this).attr('data-id');
            openpopup(id);
        });
        //close popup
        $('.cd-popup').on('click', function(event){
            if( $(event.target).is('.cd-popup-close') || $(event.target).is('.cd-popup') ) {
                event.preventDefault();
                $(this).removeClass('is-visible');
                $('html').css('overflow-y','visible');
            }
        });
        //close popup when clicking the esc keyboard button
        $(document).keyup(function(event){
            if(event.which=='27'){
                $('.cd-popup').removeClass('is-visible');
            }
        });
    }

    //open popup
    function openpopup(id) {
        $("#pop"+id+"").addClass('is-visible');
        $('html').css('overflow-y','hidden');
    }

    // Ajustement pour le scroll des ancres
    function checkIfAnchor(event) {
        if($(this).attr('href').indexOf('#') === 0) {
            event.stopPropagation();
            event.preventDefault();
            var url = $(this).attr('href');
            var id = url.split('#')[1];
            var offset = $('#'+id).offset().top - 200;
            $('html, body').animate({scrollTop: offset}, 1200);
        }
    }

    function testerFormulaire(e) {
        if($('#contactform').length === 0) {
            e.preventDefault();

            // vérification des saisies utilisateurs
            nbChampVide = 0;
            $('.error-msg').fadeOut(200);
            $('.errorEmail').fadeOut(200);
            $('input.error').removeClass('error');
            $('input.valid').removeClass('valid');

            // On vérifie que l'utilisateur a coché les cases checkbox obligatoires
            $('.form .wpcf7-checkbox.wpcf7-validates-as-required').each(function () {
                if($(this).find('input[type="checkbox"]').prop( "checked" ) == false) {
                    $(this).find('input[type="checkbox"]').parent().parent().parent().parent().parent().children('.error-msg').fadeIn(300);
                    nbChampVide++;
                }
            });

            // On vérifie s'il y a des select list obligatoire
            $('.form select.wpcf7-validates-as-required').each(function () {
                if($(this).val() == '' || $(this).val() == ' ' || $(this).val() === '- Aucun -') {
                    $(this).addClass('error');
                    $(this).parent().parent().children('.error-msg').fadeIn(300);
                    nbChampVide++;
                }else {
                    $(this).addClass('valid');
                }
            });

            // On vérifie la saisie des champs obligatoire
            $('.form input.wpcf7-validates-as-required, .wpcf7-form input.wpcf7-validates-as-required').each(function() {
                if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).addClass('error');
                    $(this).parent().parent().children('.error-msg').fadeIn(300);
                }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
                    var name = $(this).attr('name');
                    if(name === 'email') {
                        let emailVal = $(this).val();
                        emailVal = emailVal.replace('.@', '@');
                        emailVal = emailVal.replace(' ', '');
                        $(this).val(emailVal);
                        var regex = /^[\w\.\-]+[\w]@([\w-]+\.)+[\w-]{2,4}/g;
                        if (regex.test($(this).val()) == false) {
                            nbChampVide++;
                            $(this).addClass('error');
                            $(this).parent().parent().children('.error-msg').fadeIn(300);
                        }else {
                            $(this).addClass('valid');
                        }
                    }else if(name === 'telephone') {
                        var regexPhone = /^((\+|00)33\s?|0)[1-7](\s?\d{2}){4}$/;
                        if (regexPhone.test($(this).val()) == false) {
                            nbChampVide++;
                            $(this).addClass('error');
                            $(this).parent().parent().children('.error-msg').fadeIn(300);
                        } else {
                            $(this).addClass('valid');
                        }
                    }else {
                        $(this).addClass('valid');
                    }
                }
            });

            // On vérifie que les textarea requis sont renseignés
            $('.form textarea.wpcf7-validates-as-required, .wpcf7-form textarea.wpcf7-validates-as-required').each(function() {
                if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).addClass('error');
                    $(this).parent().parent().children('.error-msg').fadeIn(300);
                }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
                    $(this).addClass('valid');
                }
            });

            // On vérifie le champ email spécifique (format interdit avec gouv.fr)
            if($('input[name="email"]').length > 0) {
                if($('input[name="email"]').val() !== '' && $('input[name="email"]').val() !== ' ') {
                    let emailVal = $('input[name="email"]').val();
                    emailVal = emailVal.replace('.@', '@');
                    emailVal = emailVal.replace(' ', '');
                    $('input[name="email"]').val(emailVal);
                    var regex = /^[\w\.\-]+[\w]@([\w-]+\.)+[\w-]{2,4}/g;
                    if (regex.test($('input[name="email"]').val()) === false) {
                        nbChampVide++;
                        $('input[name="email"]').addClass('error');
                        $('input[name="email"]').parent().parent().children('.error-msg').fadeIn(300);
                    }else {
                        if($('input[name="email"]').parent().parent().children('.errorEmail').length !== 0) {
                            let emailVal = $('input[name="email"]').val();
                            if(emailVal.indexOf('gouv.fr') !== -1) {
                                nbChampVide++;
                                $('input[name="email"]').addClass('error');
                                $('input[name="email"]').parent().parent().children('.errorEmail').fadeIn(300);
                            }
                        }else {
                            $('input[name="email"]').addClass('valid');
                        }
                    }
                }
            }

            if(nbChampVide != 0) {
                $("html, body").animate({ scrollTop: $('.error').offset().top - 125 }, 500);
            }else {
                $.ajax({
                    type: "POST",
                    url: $('.form, .wpcf7-form').attr('action'),
                    data: $('.form, .wpcf7-form').serialize(), // serializes the form's elements.
                    beforeSend: function() {
                        $('.loader').addClass('show');
                        $("html, body").animate({ scrollTop: $('.wpcf7-form').offset().top - 125 }, 500);
                    },
                    success: function(data) {
                        $('.confirmation-msg').addClass('show');
                    },
                    error: function(xhr, textStatus) {
                        $('.confirmation-msg').addClass('show');
                    }
                });
            }
        }
    }


